@tailwind base;
@tailwind components;
@tailwind utilities;

/* Стили по умолчанию (для экранов шириной более 1424px) */
.element {
    background-size: 40%;
    background-image: url("./images/FooterLeft.png");
}

/* Для экранов от 900px до 1424px */
@media screen and (max-width: 1424px) {
    .element {
        background-size: 50%;
    }
}

/* Для экранов от 900px до 1100px */
@media screen and (max-width: 1100px) {
    .element {
        background-size: 60%;
    }
}

/* Для экранов от 550px до 900px */
@media screen and (max-width: 900px) {
    .element {
        background-size: 70%;
    }
}

/* Для экранов шириной до 550px */
@media screen and (max-width: 550px) {
    .element {
        background-size: 100%;
    }
}

/* Стиль для экранов шире 768px */
.your-element-class {
    background-image: url("./images/DesktopBG.png");
}

/* Стиль для экранов до 768px */
@media screen and (max-width: 768px) {
    .your-element-class {
        background-image: url("./images/MobileBG.png");
    }
}
